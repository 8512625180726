var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("heads", { attrs: { "login-type": _vm.loginType } }),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "el-carousel",
            { attrs: { height: "610px" } },
            [
              _c("el-carousel-item", [
                _c(
                  "div",
                  {
                    staticClass: "banner bannerGz",
                    on: { click: _vm.gaoZhong }
                  },
                  [
                    _c("div", { staticClass: "bunner-font" }, [
                      _c("div", { staticClass: "font-img" }, [
                        _vm._v("豫教高中")
                      ])
                    ]),
                    _c("div", { staticClass: "bunner-fontA" }, [
                      _vm._v("河南省普通高中教材配套数字音像材料平台")
                    ]),
                    _c("img", {
                      staticClass: "bunner-img",
                      attrs: { src: _vm.bunnerGz }
                    })
                  ]
                )
              ]),
              _c("el-carousel-item", [
                _c(
                  "div",
                  { staticClass: "banner", on: { click: _vm.yujiao } },
                  [
                    _c("div", { staticClass: "bunner-font" }, [
                      _c("img", {
                        staticClass: "font-img",
                        attrs: { src: _vm.syfont }
                      })
                    ]),
                    _c("div", { staticClass: "bunner-fontA" }, [
                      _vm._v("河南省中小学英语教材配套数字音像资源")
                    ]),
                    _c("img", {
                      staticClass: "bunner-img",
                      attrs: { src: _vm.bunnerA }
                    })
                  ]
                )
              ])
            ],
            1
          ),
          _c("div", { staticClass: "yjindex gzindex" }, [
            _c("div", { staticClass: "yj-con" }, [
              _c("div", { staticClass: "yj-title" }, [_vm._v("豫教高中")]),
              _c("div", { staticClass: "yj-desc" }, [
                _vm._v(
                  "\n          河南省普通高中教材配套数字音像材料是以教育部制定的普通高中新课程标准为指导，依托高中教科书，由教材出版社的编写者和一线优秀教师设计、编写。配套了优质、丰富的动画、音频、视频等数字资源，并利用信息技术，实现智能交互，从而为学生理解和掌握教材知识、提升学科核心素养、提高学习成绩提供帮助。\n        "
                )
              ]),
              _c("img", {
                staticClass: "yjcontent",
                attrs: { src: _vm.gzImg, alt: "" }
              })
            ])
          ]),
          _c("div", { staticClass: "yjindex" }, [
            _c("div", { staticClass: "yj-con" }, [
              _c("div", { staticClass: "yj-title" }, [_vm._v("豫教听读")]),
              _c("div", { staticClass: "yj-desc" }, [
                _vm._v(
                  "\n          依据各版本义务教育英语教科书编制而成，针对英语学科学生需要掌握的听、说、读、写四项基本技能，匹配优质、丰富的音视频及交互式数字资源，是培养学生英语学科核心素养不可或缺的基础性学科资源。\n        "
                )
              ]),
              _c("img", {
                staticClass: "yjcontent",
                attrs: { src: _vm.yujiaoimg, alt: "" }
              })
            ])
          ]),
          _c("div", { staticClass: "jiaocaiindex" }, [
            _c(
              "div",
              { staticClass: "jiaocai-con" },
              [
                _c("div", { staticClass: "jiaocai-title" }, [
                  _vm._v("数字教材")
                ]),
                _c("div", { staticClass: "jiaocai-desc" }, [
                  _vm._v(
                    "\n          全版本、全学科、全学段的数字教材，支持多终端的使用，满足学生自学、教师教学、家长助学\n        "
                  )
                ]),
                _c(
                  "el-radio-group",
                  {
                    staticClass: "jdCheck",
                    on: { change: _vm.getList },
                    model: {
                      value: _vm.jieduan,
                      callback: function($$v) {
                        _vm.jieduan = $$v
                      },
                      expression: "jieduan"
                    }
                  },
                  _vm._l(_vm.jdList, function(item) {
                    return _c(
                      "el-radio-button",
                      {
                        key: item.code,
                        staticClass: "checknode",
                        attrs: { label: item.code }
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  1
                ),
                _vm._l(_vm.newList, function(vv, kk) {
                  return _c(
                    "div",
                    {
                      key: kk,
                      class: "books " + (kk === 0 ? "books-first" : "")
                    },
                    [
                      _vm._l(vv, function(item, k) {
                        return _c(
                          "div",
                          {
                            key: kk + "-" + k,
                            class: "book " + (k === 0 ? "book-first" : "")
                          },
                          [
                            item.id
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "book1",
                                    on: { click: _vm.isDown }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "book-img",
                                      attrs: {
                                        src: item.imageUrl,
                                        title: item.title
                                      }
                                    })
                                  ]
                                )
                              : _c("div", { staticClass: "jc-active1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "jc-active-title1",
                                      on: { click: _vm.getMore }
                                    },
                                    [_vm._v("更多")]
                                  )
                                ])
                          ]
                        )
                      }),
                      _c("div", { staticClass: "bookshelf" })
                    ],
                    2
                  )
                })
              ],
              2
            )
          ]),
          _c("div", { staticClass: "jiaocanindex" }, [
            _c("div", { staticClass: "jiaocan-con" }, [
              _c("div", { staticClass: "jiaocan-title" }, [_vm._v("数字教参")]),
              _c("div", { staticClass: "jiaocan-desc" }, [
                _vm._v(
                  "\n          内含与教材章节精准匹配的精品数字资源，一站式解决教师备授课所需要资料，让教学更加方便、快捷、高效\n        "
                )
              ]),
              _c("img", {
                staticClass: "jiaocancontent",
                attrs: { src: _vm.jiaocancontent, alt: "" },
                on: { click: _vm.goJiaocan }
              })
            ])
          ]),
          _c("div", { staticClass: "bigdata" }, [
            _vm._m(0),
            _c("span", { staticClass: "info" }, [
              _vm._v("基础教育电子资源大数据河南工程实验室 监制")
            ]),
            _c("div", { staticClass: "bigdata-main" }, [
              _c(
                "div",
                {
                  staticClass: "box",
                  staticStyle: { "margin-right": "210px" }
                },
                [
                  _c("img", { attrs: { src: _vm.teacher } }),
                  _c("p", { staticStyle: { color: "rgba(245,166,35,1)" } }, [
                    _vm._v("教师")
                  ]),
                  _vm._m(1)
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "box",
                  staticStyle: { "margin-right": "210px" }
                },
                [
                  _c("img", { attrs: { src: _vm.student } }),
                  _c("p", { staticStyle: { color: "rgba(24,144,255,1)" } }, [
                    _vm._v("学生")
                  ]),
                  _vm._m(2)
                ]
              ),
              _c("div", { staticClass: "box" }, [
                _c("img", { attrs: { src: _vm.parent } }),
                _c("p", { staticStyle: { color: "rgba(255,73,73,1)" } }, [
                  _vm._v("家长")
                ]),
                _vm._m(3)
              ])
            ])
          ]),
          _c("div", { staticClass: "link" }, [
            _c("div", { staticClass: "title" }, [
              _c("img", {
                staticStyle: { "margin-right": "15px" },
                attrs: { src: _vm.link }
              }),
              _c("span", { staticClass: "title-info" }, [_vm._v("友情链接")])
            ]),
            _c("div", { staticClass: "link-main" }, [
              _c(
                "a",
                { attrs: { target: "blank", href: "http://www.moe.gov.cn/" } },
                [_c("img", { attrs: { src: _vm.link1 } })]
              ),
              _c(
                "a",
                {
                  attrs: {
                    target: "blank",
                    href: "http://www.ncet.edu.cn/zhuzhan/index.html"
                  }
                },
                [_c("img", { attrs: { src: _vm.link2 } })]
              ),
              _c(
                "a",
                {
                  attrs: { target: "blank", href: "http://jyt.henan.gov.cn/" }
                },
                [_c("img", { attrs: { src: _vm.link3 } })]
              ),
              _c(
                "a",
                { attrs: { target: "blank", href: "http://www.hner.cn/" } },
                [_c("img", { attrs: { src: _vm.link4 } })]
              ),
              _c(
                "a",
                {
                  attrs: {
                    target: "blank",
                    href: "http://www.hnszjc.com/index.html"
                  }
                },
                [_c("img", { attrs: { src: _vm.link5 } })]
              )
            ])
          ])
        ],
        1
      ),
      _c("foots")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("p", [_vm._v("大数据学科质量测评")]),
      _c("p", [_vm._v("开启数据驱动教学新篇章")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-message" }, [
      _c("span", [_vm._v("全面知晓学情")]),
      _c("span", [_vm._v("实施精准教学")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-message" }, [
      _c("span", [_vm._v("明晰问题修补短板")]),
      _c("span", [_vm._v("避免重复无效训练")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-message" }, [
      _c("span", [_vm._v("了解孩子更彻底")]),
      _c("span", [_vm._v("共育更有针对性")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }