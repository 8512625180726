<template>
  <!-- 首页 -->
  <div>
    <heads :login-type="loginType" />
    <div class="main">
      <!-- <div class="banner"
        @click="yujiao">
        <div class="bunner-font">
          <img :src="syfont"
            class="font-img">
        </div>
        <div class="bunner-fontA">河南省中小学英语教材配套数字音像资源</div>
        <img :src="bunnerA"
          class="bunner-img">
      </div> -->
      <el-carousel height="610px">
        <el-carousel-item>
          <div class="banner bannerGz" @click="gaoZhong">
            <div class="bunner-font">
              <div class="font-img">豫教高中</div>
            </div>
            <div class="bunner-fontA">河南省普通高中教材配套数字音像材料平台</div>
            <img :src="bunnerGz" class="bunner-img" />
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="banner" @click="yujiao">
            <div class="bunner-font">
              <img :src="syfont" class="font-img" />
            </div>
            <div class="bunner-fontA">河南省中小学英语教材配套数字音像资源</div>
            <img :src="bunnerA" class="bunner-img" />
          </div>
        </el-carousel-item>
      </el-carousel>
      <!-- 豫教高中 -->
      <div class="yjindex gzindex">
        <div class="yj-con">
          <div class="yj-title">豫教高中</div>
          <div class="yj-desc">
            河南省普通高中教材配套数字音像材料是以教育部制定的普通高中新课程标准为指导，依托高中教科书，由教材出版社的编写者和一线优秀教师设计、编写。配套了优质、丰富的动画、音频、视频等数字资源，并利用信息技术，实现智能交互，从而为学生理解和掌握教材知识、提升学科核心素养、提高学习成绩提供帮助。
          </div>
          <img :src="gzImg" class="yjcontent" alt="" />
        </div>
      </div>
      <!-- 豫教听读 -->
      <div class="yjindex">
        <div class="yj-con">
          <div class="yj-title">豫教听读</div>
          <div class="yj-desc">
            依据各版本义务教育英语教科书编制而成，针对英语学科学生需要掌握的听、说、读、写四项基本技能，匹配优质、丰富的音视频及交互式数字资源，是培养学生英语学科核心素养不可或缺的基础性学科资源。
          </div>
          <img :src="yujiaoimg" class="yjcontent" alt="" />
        </div>
      </div>
      <!-- 数字教材 -->
      <div class="jiaocaiindex">
        <div class="jiaocai-con">
          <div class="jiaocai-title">数字教材</div>
          <div class="jiaocai-desc">
            全版本、全学科、全学段的数字教材，支持多终端的使用，满足学生自学、教师教学、家长助学
          </div>
          <el-radio-group v-model="jieduan" class="jdCheck" @change="getList">
            <el-radio-button v-for="item in jdList" :key="item.code" :label="item.code" class="checknode">{{
              item.name
            }}</el-radio-button>
          </el-radio-group>
          <div v-for="(vv, kk) in newList" :key="kk" :class="`books ${kk === 0 ? 'books-first' : ''}`">
            <div v-for="(item, k) in vv" :key="`${kk}-${k}`" :class="`book ${k === 0 ? 'book-first' : ''}`">
              <div v-if="item.id" class="book1" @click="isDown">
                <!-- 教材 -->
                <img :src="item.imageUrl" :title="item.title" class="book-img" />
              </div>
              <div v-else class="jc-active1">
                <div class="jc-active-title1" @click="getMore">更多</div>
              </div>
            </div>
            <div class="bookshelf" />
          </div>
        </div>
      </div>
      <!-- 数字教参 -->
      <div class="jiaocanindex">
        <div class="jiaocan-con">
          <div class="jiaocan-title">数字教参</div>
          <div class="jiaocan-desc">
            内含与教材章节精准匹配的精品数字资源，一站式解决教师备授课所需要资料，让教学更加方便、快捷、高效
          </div>
          <img :src="jiaocancontent" class="jiaocancontent" alt="" @click="goJiaocan" />
        </div>
      </div>
      <div class="bigdata">
        <div class="title">
          <p>大数据学科质量测评</p>
          <p>开启数据驱动教学新篇章</p>
        </div>
        <span class="info">基础教育电子资源大数据河南工程实验室 监制</span>
        <div class="bigdata-main">
          <div class="box" style="margin-right:210px;">
            <img :src="teacher" />
            <p style="color:rgba(245,166,35,1);">教师</p>
            <div class="info-message">
              <span>全面知晓学情</span>
              <span>实施精准教学</span>
            </div>
          </div>
          <div class="box" style="margin-right:210px;">
            <img :src="student" />
            <p style="color:rgba(24,144,255,1);">学生</p>
            <div class="info-message">
              <span>明晰问题修补短板</span>
              <span>避免重复无效训练</span>
            </div>
          </div>
          <div class="box">
            <img :src="parent" />
            <p style="color:rgba(255,73,73,1);">家长</p>
            <div class="info-message">
              <span>了解孩子更彻底</span>
              <span>共育更有针对性</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="notice">
        <div class="title">
          <img :src="notice"
            style="margin-right:15px;">
          <span class="title-info">活动通知</span>
        </div>
        <div class="notice-main">
          <div style="width:550px;">
            <div v-for="item in noticeListLeft"
              :key="item.id"
              class="notice-other">
              <router-link :to="{name:'articleDetails',query: {id: item.id}}">
                <span class="title-other">{{item.title || '暂无标题'}}</span>
                <span class="time-other">{{item.createTime}}</span>
              </router-link>
            </div>
          </div>
          <div style="width:550px;">
            <div v-for="item in noticeListRight"
              :key="item.id"
              class="notice-other">
              <router-link :to="{name:'articleDetails',query: {id: item.id}}">
                <span class="title-other">{{item.title || '暂无标题'}}</span>
                <span class="time-other">{{item.createTime}}</span>
              </router-link>
            </div>
          </div>
        </div>
        <router-link v-show="total >= 10"
          :to="{name: 'notice'}"
          class="see-more">
          查看更多 >
        </router-link>
      </div> -->
      <!-- <div class="wechat-img-style">
        <img :src="wechatImg">
        <span>关注公众号了解更多信息</span>
      </div> -->
      <div class="link">
        <div class="title">
          <img :src="link" style="margin-right:15px;" />
          <span class="title-info">友情链接</span>
        </div>
        <div class="link-main">
          <a target="blank" href="http://www.moe.gov.cn/"><img :src="link1"/></a>
          <a target="blank" href="http://www.ncet.edu.cn/zhuzhan/index.html"><img :src="link2"/></a>
          <a target="blank" href="http://jyt.henan.gov.cn/"><img :src="link3"/></a>
          <a target="blank" href="http://www.hner.cn/"><img :src="link4"/></a>
          <a target="blank" href="http://www.hnszjc.com/index.html"><img :src="link5"/></a>
        </div>
      </div>
    </div>
    <foots />
    <!-- <contant /> -->
  </div>
</template>
<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import { openUriWithInputTimeoutHack } from './common/isInitClient';
import { articleListGet, goodsGet, getCommentCache } from './api.js';
import teacher from '../assets/img/index/teacher.png';
import student from '../assets/img/index/student.png';
import parent from '../assets/img/index/parent.png';
import notice from '../assets/img/index/notice.svg';
import link from '../assets/img/index/link.svg';
import link1 from '../assets/img/index/link1.png';
import link2 from '../assets/img/index/link2.png';
import link3 from '../assets/img/index/link3.png';
import link4 from '../assets/img/index/link4.png';
import link5 from '../assets/img/index/link5.png';
import bunnerA from '../assets/img/index/bunnerA.png';
import bunnerGz from '../assets/img/index/bannerGz.png';
import syfont from '../assets/img/index/syfont.svg';
import jiaocancontent from '../assets/pic/jiaocancontent.png';
import yujiaoimg from '../assets/img/index/yujiaoimg.png';
import gzImg from '../assets/img/index/gzImg.png';

import heads from './common/head';
import foots from './common/foot';
// import contant from './common/contant';

export default {
  components: {
    heads,
    foots,
    // contant,
  },
  data() {
    const { szjcDown } = window.config;
    const { loginType = '' } = this.$route.query || {};
    return {
      yujiaoimg,
      gzImg,
      syfont,
      loginType, // TODO::满足98测试公共登录
      teacher,
      student,
      parent,
      notice,
      link,
      link1,
      link2,
      link3,
      link4,
      link5,
      bunnerA,
      bunnerGz,
      jiaocancontent,
      szjcDown,
      noticeListLeft: [],
      noticeListRight: [],
      // 通知条数
      total: '',
      // 轮播图图片地址路径,跳转地址信息
      // 教材
      jieduan: '1',
      jdList: [{ name: '小学', code: '1' }, { name: '中学', code: '2' }],
      newList: [],
      gaozhong: 'a,b,c',
      // 年级
      gradeList: [],
      // 科目
      subjectList: [],
      // 出版社
      versionList: [],
      // 分册
      volumeList: [],
    };
  },
  created() {
    articleListGet({ page: 1, pageSize: 10, status: '1', source: 'szjcyyy' }).then(({ data: { data: noticeList } }) => {
      if (!_.isEmpty(noticeList)) {
        _.each(noticeList, v => {
          v.createTime = v.createTime ? dayjs(v.createTime).format('YYYY-MM-DD') : '暂无时间';
        });
        // 处理时间
        const { length } = noticeList;
        this.total = length;
        const leftLength = Math.ceil(length / 2);
        // 分隔数组,使左右列表数目相等
        const list = _.chunk(noticeList, leftLength);
        if (list.length === 1) {
          this.noticeListLeft = _.head(list);
        } else {
          this.noticeListLeft = _.head(list);
          this.noticeListRight = _.last(list);
        }
        // console.log(this.noticeListLeft, this.noticeListRight);
      }
    });
    this.init();
  },
  methods: {
    yujiao() {
      window.open('http://hnitd.com', '_blank');
    },
    gaoZhong() {
      window.open('https://hnigz.com/', '_blank');
    },
    /* 获取书架列表 */
    getList() {
      const args = {
        page: 1,
        pageSize: 9,
        status: '1',
        section: this.jieduan,
      };
      goodsGet(args).then(({ data, err }) => {
        if (!err) {
          const { data: tableList1 } = data;
          const tableList = _.orderBy(tableList1, ['grade', 'subject', 'volume']);
          this.newList = [];
          this.tableList = _.map(tableList, val => {
            let title = '';
            title += (_.find(this.gradeList, { code: _.parseInt(val.grade) }) || {}).name || '';
            title += ' ';
            title += (_.find(this.subjectList, { code: val.subject }) || {}).name || '';
            title += ' ';
            title += (_.find(this.versionList, { code: val.supplierCode }) || {}).name || '';
            title += ' ';
            title += (_.find(this.volumeList, { code: _.parseInt(val.volume) }) || {}).name || '';
            return {
              ...val,
              title: this.gaozhong.indexOf(val.grade) === -1 ? title : val.name,
            };
          });
          this.tableList.push({});
          this.arrayToTwo();
        }
      });
    },
    /* 把教材列表处理成二维数组 */
    arrayToTwo() {
      let tempList = [];
      this.newList = [];
      // 为了增加一个激活教材按钮
      _.each(this.tableList, (val, key) => {
        tempList.push(val);
        if ((key + 1) % 5 === 0) {
          this.newList.push(tempList);
          tempList = [];
        }
      });
      if (!_.isEmpty(tempList)) {
        this.newList.push(tempList);
      }
    },
    /* 获取字典信息 */
    init() {
      getCommentCache({ term: 'common_paper' }).then(v => {
        const {
          data: [{ data = [] }],
        } = v.data || [];
        this.gradeList = (data[2] || {}).data || [];
        this.subjectList = (data[3] || {}).data || [];
        this.versionList = (data[4] || {}).data || [];
        this.volumeList = (data[5] || {}).data || [];
        this.getList();
      });
    },
    /* 更多教材 */
    getMore() {
      this.$router.push({ path: '/jiaocai' });
    },
    goJiaocan() {
      // this.$router.push({ path: '/jiaocan' });
    },
    isDown() {
      console.log(`hnszjc://open?token=${this.cookie}&appId=${this.appId}`);
      // 判断客户端是否已下载
      openUriWithInputTimeoutHack(
        `hnszjc://open?token=${this.cookie}&appId=${this.appId}`,
        () => {
          this.$confirm('下载数字教材客户端，打开「数字教材」模块，激活教材后查看内容', '下载数字教材客户端', {
            distinguishCancelAndClose: true,
            confirmButtonText: '下载客户端',
            cancelButtonText: '取消',
          })
            .then(() => {
              // 未下载
              this.downClientpath();
            })
            .catch(() => {});
        },
        () => {
          // this.$confirm('打开数字教材客户端，在「数字教材」模块，激活教材后查看内容', '提示', {
          //   distinguishCancelAndClose: true,
          //   confirmButtonText: '打开客户端',
          //   cancelButtonText: '取消',
          // })
          //   .then(() => {  })
          //   .catch(() => { });
          //   已下载,直接打开
          // window.open('hnszjc:///open', '_self');
        },
      );
    },
    /* 下载客户端 */
    downClientpath() {
      window.open(this.szjcDown, '_target');
    },
  },
};
</script>
<style lang="less">
.checknode {
  .el-radio-button__inner {
    width: 88px;
    height: 32px;
    border-radius: 0px !important;
    font-size: 14px;
  }
}
</style>

<style lang="less" scoped>
.main {
  // max-width: 1920px;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  margin-top: 143px;
  .banner {
    cursor: pointer;
    width: 100%;
    height: 610px;
    overflow: hidden;
    text-align: center;
    vertical-align: middle;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../assets/img/index/idxBunner.png');
    .bunner-font {
      color: #ffffff;
      height: 110px;
      margin-top: 68px;
      .font-img {
        height: 110px;
      }
    }
    .bunner-fontA {
      height: 28px;
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      margin-top: 32px;
      text-align: center;
    }
    .bunner-img {
      width: 1228px;
      height: 316px;
      margin-top: 56px;
    }
  }
  .bannerGz {
    background-image: url('../assets/img/index/bg_gz.png');
    .font-img {
      font-weight: 600;
      font-size: 104px;
      color: #290202;
      line-height: 145px;
    }
    .bunner-fontA {
      font-size: 36px;
      color: #290202;
    }
    .bunner-img {
      width: 1026px;
      height: 332px;
      margin-top: 40px;
    }
  }
  /* 数字教材 */
  .jiaocaiindex {
    width: 100%;
    height: 860px;
    background: rgba(250, 250, 250, 1);
    .jiaocai-con {
      width: 1200px;
      margin: auto;
      padding-top: 80px;
    }
    .jiaocai-title {
      height: 59px;
      font-size: 42px;
      text-align: center;
      color: #262626;
      line-height: 59px;
    }
    .jiaocai-desc {
      height: 28px;
      font-size: 20px;
      text-align: center;
      color: #262626;
      line-height: 28px;
      margin-top: 14px;
    }
    .jdCheck {
      display: block;
      text-align: center;
      margin-top: 26px;
    }
    .checknode {
      width: 88px;
      height: 32px;
    }
    .book {
      display: inline-block;
      text-align: center;
      width: 150px;
      height: 200px;
      margin-left: 40px;
      border-radius: 4px;
      position: relative;
      cursor: pointer;
      z-index: 99;
    }
    .book1 {
      display: inline-block;
      text-align: center;
      width: 150px;
      height: 200px;
      border-radius: 4px;
      position: relative;
    }
    .book-first {
      margin-left: 60px;
    }
    .book1:hover {
      box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.3);
    }
    .books {
      width: 1024px;
      height: 236px;
      margin: auto;
      position: relative;
      margin-top: 26px;
    }
    .books-first {
      margin-top: 48px;
    }
    .bookshelf {
      width: 1024px;
      height: 74px;
      position: absolute;
      bottom: -20px;
      left: 0px;
      background: url('../assets/pic/bookshelf.png') no-repeat;
      background-size: 100% 100%;
      z-index: 1;
    }
    .book-img {
      width: 148px;
      height: 198px;
      border-radius: 4px;
      border: 1px solid #e8e8e8;
      z-index: 90;
    }
    .jc-active1 {
      float: left;
      position: relative;
      text-align: center;
      width: 148px;
      height: 198px;
      background: linear-gradient(180deg, rgba(247, 247, 247, 1) 0%, rgba(228, 228, 228, 1) 100%);
      border-radius: 2px;
      &:hover {
        box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.3);
      }
    }
    .jc-active-title1 {
      margin: auto;
      margin-top: 80px;
      width: 88px;
      height: 32px;
      font-size: 14px;
      color: #1890ff;
      line-height: 32px;
      text-align: center;
      border: 1px solid #1890ff;
      border-radius: 2px;
      cursor: pointer;
    }
  }
  /* 数字教参 */
  .jiaocanindex {
    width: 100%;
    height: 900px;
    background: rgba(255, 255, 255, 1);
    .jiaocan-con {
      width: 1200px;
      margin: auto;
      padding-top: 80px;
    }
    .jiaocan-title {
      height: 59px;
      font-size: 42px;
      text-align: center;
      color: #262626;
      line-height: 59px;
    }
    .jiaocan-desc {
      height: 28px;
      font-size: 20px;
      text-align: center;
      color: #262626;
      line-height: 28px;
      margin-top: 14px;
    }
    .jiaocancontent {
      cursor: pointer;
      display: block;
      width: 904px;
      height: 649px;
      margin: auto;
      margin-top: 69px;
    }
  }

  /* 数字教参 */
  .yjindex {
    width: 100%;
    height: 850px;
    background: rgba(255, 255, 255, 1);
    .yj-con {
      width: 1200px;
      margin: auto;
      padding-top: 80px;
    }
    .yj-title {
      height: 59px;
      font-size: 42px;
      text-align: center;
      color: #262626;
      line-height: 59px;
    }
    .yj-desc {
      height: 28px;
      font-size: 20px;
      text-align: center;
      color: #262626;
      line-height: 28px;
      margin-top: 14px;
    }
    .yjcontent {
      display: block;
      width: 820px;
      height: 600px;
      margin: auto;
      margin-top: 69px;
    }
  }
  .gzindex {
    height: 890px;
    background: #fafafa;
    .yjcontent {
      margin-top: 90px;
    }
  }
  .bigdata {
    width: 100%;
    height: 700px;
    background: rgba(250, 250, 250, 1);
    .title {
      padding-top: 66px;
      text-align: center;
      p {
        font-size: 42px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        color: rgba(38, 38, 38, 1);
        line-height: 59px;
      }
    }
    .info {
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Light;
      font-weight: 300;
      color: rgba(38, 38, 38, 1);
      line-height: 28px;
      margin-top: 18px;
      text-align: center;
      display: block;
    }
    .bigdata-main {
      margin-top: 56px;
      display: flex;
      justify-content: center;
      .box {
        width: 200px;
        p {
          height: 40px;
          font-size: 28px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          line-height: 40px;
          margin-top: 43px;
          text-align: center;
        }
        .info-message {
          margin-top: 13px;
          span {
            height: 28px;
            font-size: 20px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            color: rgba(38, 38, 38, 1);
            line-height: 14px;
            display: block;
            text-align: center;
          }
        }
      }
    }
  }
  .notice {
    width: 1200px;
    margin: 80px auto 0;
    .title {
      margin-bottom: 42px;
      height: 40px;
      display: flex;
      .title-info {
        width: 112px;
        height: 40px;
        font-size: 28px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        color: rgba(38, 38, 38, 1);
        line-height: 40px;
      }
    }
    .notice-main {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .notice-first {
        width: 100%;
        display: flex;
        cursor: pointer;
        .title-first {
          width: 100%;
          height: 84px;
          font-size: 20px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          color: rgba(38, 38, 38, 1);
          line-height: 28px;
          margin-top: 15px;
          display: block;
          overflow: hidden;
        }
        .time-first {
          width: 123px;
          height: 28px;
          font-size: 20px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          color: rgba(140, 140, 140, 1);
          line-height: 28px;
          margin-top: 16px;
          display: block;
        }
      }
      .notice-other {
        width: 100%;
        margin-bottom: 24px;
        .title-other {
          width: 100%;
          height: 25px;
          font-size: 18px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          color: rgba(38, 38, 38, 1);
          line-height: 25px;
          // margin-top: 24px;
          display: block;
          overflow: hidden;
          cursor: pointer;
        }
        .time-other {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          color: rgba(140, 140, 140, 1);
          line-height: 22px;
          margin-top: 2px;
          display: block;
        }
      }
    }
    .see-more {
      width: 101px;
      margin: 0 auto;
      display: block;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #1890ff;
      line-height: 28px;
      margin-top: 14px;
      cursor: pointer;
    }
  }
  .link {
    width: 1200px;
    margin: 38px auto;
    .title {
      margin-bottom: 24px;
      height: 40px;
      display: flex;
      .title-info {
        width: 112px;
        height: 40px;
        font-size: 28px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        color: rgba(38, 38, 38, 1);
        line-height: 40px;
      }
    }
    .link-main {
      display: flex;
      margin-bottom: 34px;
      img {
        margin-right: 12px;
        cursor: pointer;
      }
    }
  }
}
</style>
